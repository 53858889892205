import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';
import SuccessImg from '../../images/svg-gcbooks/success';
import ShortArrayLeft from '../../images/short-arrow-left.svg';

import DefaultLayoutComponent from '../../modules/layouts/DefaultLayoutComponent';
import { useFetch } from '../../hooks';
import { currencySymbols } from '../../modules/timeline/components/EnterButton/utils';
import { Link } from 'gatsby';

const DonateTransactions = (props) => {
  const [getDonationHistoryDetails, donationHistory, error, loading] = useFetch('GET');

  React.useEffect(() => {
    if (props.id) {
      getDonationHistoryDetails('api/donate/donate-transactions-details-by-id/' + props.id, {
        onSuccess: (res) => {},
        onError: () => {},
      });
    }
  }, [props]);

  return (
    <BodyClassName className=" bg-dark donation-invoice ">
      <DefaultLayoutComponent title="Donation Invoice">
        <div className="site-main">
          <div className="  ">
            <div className=" wrapper  pd-x-md   wrapper-xl">
              <Link
                to="/donation-history"
                style={{ display: 'flex', color: 'white', gap: '4px', marginBottom: '42px', marginTop: '80px' }}
              >
                <img src={ShortArrayLeft} />
                <p style={{ color: '#FFFFFF' }}>Back</p>
              </Link>
            </div>
            <div className="wrapper bg-white  wrapper-xl ">
              <div className="section pd-x-md ">
                <div className="wrapper wrapper-lg">
                  <div style={{ marginTop: '60px', marginBottom: '120px' }}>
                    <img src={'https://images.prismic.io/lineage/ZiVVCPPdc1huKqQz_Lineage.png?auto=format,compress'} />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <p className="weight-700 ">Bill To:</p>
                      <p className="weight-700 " style={{ marginTop: '16px' }}>
                        {donationHistory?.data?.donorId?.firstName} {donationHistory?.data?.donorId?.lastName}
                      </p>
                      <p className="text-gray-80" style={{ marginTop: '16px' }}>
                        {donationHistory?.data?.donorId?.address.addressLine2}{' '}
                        {donationHistory?.data?.donorId?.address.addressLine1}
                      </p>
                      <p className="text-gray-80">
                        {donationHistory?.data?.donorId?.address.city}, {donationHistory?.data?.donorId?.address.state}{' '}
                        , {donationHistory?.data?.donorId?.address.country}
                      </p>

                      <p className="text-gray-80">Email: {donationHistory?.data?.donorId?.email}</p>
                      <p className="text-gray-80">Phone: {donationHistory?.data?.donorId?.phone}</p>
                    </div>

                    <div>
                      <div style={{ display: 'flex', gap: '54px', justifyContent: 'space-between' }}>
                        <p className="weight-700">Invoice number:</p>
                        <p> {donationHistory?.data?.paymentDetails?.stripeInvoiceId}</p>
                      </div>
                      <div style={{ display: 'flex', gap: '54px', justifyContent: 'space-between' }}>
                        <p className="weight-700">Date:</p>
                        <p>{new Date(donationHistory?.data?.createdAt).toDateString()}</p>
                      </div>
                    </div>
                  </div>

                  <table className="table-container">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Giving Type</th>
                        <th>Amount</th>
                      </tr>
                    </thead>

                    {/* Table body */}
                    <tbody
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      <tr>
                        <td>{new Date(donationHistory?.data?.createdAt).toDateString()}</td>
                        <td>
                          {donationHistory?.data?.donatedProject?.subName
                            ? donationHistory?.data?.donatedProject?.subName
                            : donationHistory?.data?.donatedProject?.name}
                        </td>
                        <td>{donationHistory?.data?.donateType}</td>
                        <td>
                          {
                            currencySymbols[
                              donationHistory?.data?.currency ? donationHistory?.data?.currency.toUpperCase() : 'USD'
                            ]
                          }
                          {donationHistory?.data?.amount}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div>
                    <p className="weight-700" style={{ marginTop: '200px' }}>
                      Payment Details:
                    </p>

                    <div style={{ marginTop: '8px' }}>
                      <span>Credit Card:</span>
                      <span>
                        {' '}
                        **** **** **** {donationHistory?.data?.paymentDetails?.creditCardLastFour} (
                        {donationHistory?.data?.paymentDetails?.brand})
                      </span>
                    </div>
                    <div style={{ marginTop: '8px' }}>
                      <span>Expiration Date:</span>
                      <span> {donationHistory?.data?.paymentDetails?.expirationDate}</span>
                    </div>
                    <div style={{ marginTop: '8px' }}>
                      <span>Name on Card:</span>
                      <span>
                        {' '}
                        {donationHistory?.data?.cardHolderName
                          ? donationHistory?.data?.cardHolderName
                          : donationHistory?.data?.donorId.firstName + ' ' + donationHistory?.data?.donorId.lastName}
                      </span>
                    </div>
                  </div>

                  <div style={{ marginTop: '48px' }}>
                    <p className="weight-700">*Tax Deductibility Information:</p>
                    <p>
                      Our organization is a registered nonprofit, and your donation may be tax- <br />
                      deductible. Consult your tax advisor for guidance.
                    </p>
                  </div>

                  <h4 style={{ marginTop: '80px' }}>Thank you for your support!</h4>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: '#FECB04',
                  padding: '35px 0px',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '11px',
                }}
              >
                <a>
                  <img
                    src={'https://images.prismic.io/lineage/ZiVVDPPdc1huKqQ3_facebook_icon.png?auto=format,compress'}
                  />
                </a>
                <a>
                  <img
                    src={'https://images.prismic.io/lineage/ZiVVC_Pdc1huKqQ2_instagram_icon.png?auto=format,compress'}
                  />
                </a>
                <a>
                  <img
                    src={'https://images.prismic.io/lineage/ZiVVCvPdc1huKqQ1_twitter_icon.png?auto=format,compress'}
                  />
                </a>
                <a>
                  <img
                    src={'https://images.prismic.io/lineage/ZiVVCfPdc1huKqQ0_youtube_icon.png?auto=format,compress'}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default DonateTransactions;
