import React from 'react';

function SuccessImg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="112" height="90" fill="none" viewBox="0 0 112 90">
      <ellipse cx="55.962" cy="83.654" fill="#000" fillOpacity="0.04" rx="55.962" ry="6.346"></ellipse>
      <circle cx="56.619" cy="49.385" r="29.385" fill="#fff" stroke="#60B590" strokeWidth="2"></circle>
      <path
        stroke="#60B590"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M70.918 38.661l-19.661 19.66-8.937-8.936"
      ></path>
    </svg>
  );
}

export default SuccessImg;
